import React, { Component } from 'react';
import { Row, Col, Button } from 'antd';
import { Icon, Slider, LabelSelect } from 'libs';
import Ability from '../../../ability';
import WebApp from '../../../webapp';
import plugin from 'plugin';
import pubsub from 'utils/pubsub';
import {BrowserType} from 'utils/BrowserType';
class ZoomFocusSet extends Component {
    constructor (props) {
        super(props);
        this.state = {
            zoomStep: 20, // 缩放选择步长，默认为20
            focusStep: 20, // 聚焦选择步长，默认为20
            zoomList: [{ value: 1, text: 1 }, { value: 5, text: 5 }, { value: 20, text: 20 }],
            focusList: [{ value: 1, text: 1 }, { value: 5, text: 5 }, { value: 20, text: 20 }],
            // currentZoom: 2, // 当前缩放显示index
            // currentFocus: 2, // 当前聚焦显示index
            isAutoFocus: false, // 是否在进行自动聚焦动作
            isShowReginFocus: false, //是否显示区域聚焦
            isShowZoom: true, //是否显示缩放
            isShowFocus: true, //是否显示聚焦
            sdDeviceType: false, //是否显示球机后焦能力
            longPress: false, //是否长按
            LensInfo: 'normal', //镜头类型
            isClickReginFocus: false, // 点否主动点击了区域聚焦
            channel: WebApp.CHANNEL_NUMBER === 1 ? 0 : props.specialChannel, // 聚焦缩放通道从0开始
        };
        this.b_zoom = false; //是否修改过缩放的值  指web主动修改
        this.b_focus = false; //是否修改过聚焦的值   指web主动修改
        this.b_autofocus = false; //当web上修改聚焦缩放的值后，控件回调是否出现过'AutoFoucs'。
        this.b_zoomfocus = false; //cs 是否修改过缩放聚焦的值
        this.iTime = null;
        // this.isTPC = WebApp.DeviceType.indexOf('TPC') !== -1;
    }

    componentDidMount () {
        let { getFocusStatus, currentChannel, getLensInfo, getBackFocusStatus } = this.props;
        getLensInfo().then((info) => {
            //可替换镜头，不知道总步长
            if(info && info.Type === 3) {
                this.setState({LensInfo: 'cs'});
            }
        });
        let arr = [Ability.get('VideoInputCapsEx'), Ability.get('VideoInputCaps'), Ability.get('BFFunction')];
        Promise.all(arr.map(p => p.catch(e => e))).then(json => {
            let [VideoInputCapsEx, VideoInputCaps, BFFunction] = json;
            if (VideoInputCapsEx && VideoInputCapsEx.VideoInFocus && VideoInputCapsEx.VideoInFocus.SupportFocusRegion && !WebApp.IsSpecialBrowse) {
                this.setState({ isShowReginFocus: true });
            }
            if (VideoInputCapsEx && VideoInputCapsEx.ABFFunction.Support) {
                this.setState({isShowZoom: false});
            }
            if (VideoInputCaps && VideoInputCaps.SyncFocus) {
                this.setState({isShowFocus: false});
            }
            //球机后焦能力
            if (BFFunction && BFFunction.Support) {
                this.setState({
                    sdDeviceType: BFFunction.Support,
                    isShowZoom: false
                });
            }
            BFFunction && BFFunction.Support ? getBackFocusStatus(currentChannel) : getFocusStatus(currentChannel);
        });

        plugin.addEvent('FocusStatus', (f, z, s) => {
            // console.log(s);

            //如果web下发修改，并且还未出现"AutoFocus"，则直接返回不处理
            // if ((!this.b_zoom || !this.b_focus) && s === 'Normal' && !this.b_autofocus) return;
            // if (this.b_zoom) {
            //     this.changeSlide.bind(this, 'Zoom');
            // }
            // if (this.b_focus) {
            //     this.changeSlide.bind(this, 'Focus');
            // }

            // if (s === 'Normal') {
            //     this.b_zoom = false;
            //     this.b_focus = false;
            //     this.setState({isAutoFocus: false});
            //     // this.refresh();
            //     // plugin.ActivateAutoFocus(this.CHANNEL, false);
            // } else {
            //     this.b_autofocus = true;
            //     this.setState({isAutoFocus: true});
            // }
        });

        // 局部放大和手动聚焦互斥，如果开启了局部放大，关闭手动聚焦
        pubsub.subscribe('preview_onLocalRange', data => {
            if (data) {
                // this.setState({isCurrent: false});
                plugin.DeactivateReactAreaSelection();
            }
        });
    }

    componentDidUpdate (prevProps) {
        // if (WebApp.CHANNEL_NUMBER > 1) {
        //     if (this.props.specialChannel !== prevProps.specialChannel) {
        //         this.setState({channel: this.props.specialChannel}, () => {
        //             if(this.state.channel === -1) return;
        //             this.refresh();
        //         });
        //     }
        //     // 单画面的时候，切换通道，聚焦缩放的信息需要同时变化
        //     if (prevProps.currentChannel !== this.props.currentChannel) {
        //         if (this.props.Preview.isSingleMode) {
        //             this.setState({channel: this.props.currentChannel}, () => {
        //                 if(this.state.channel === -1) return;
        //                 this.refresh();
        //             });
        //         }
        //     }
        // }
    }

    componentWillUnmount () {
        clearInterval(this.Time);
        plugin.addEvent('FocusStatus', null);
        // plugin.addEvent('ReactAreaSelected',null);
    }

    /**
     * @description 重复请求，在status没有变成normal之前一直请求数据
     */
    getStatus = () => {
        let { getFocusStatus, getBackFocusStatus } = this.props;
        const { sdDeviceType, channel } = this.state;
        this.setState({isAutoFocus: true});
        if(sdDeviceType) {
            //球机获取后焦状态接口与IPC不一样
            getBackFocusStatus(channel).then(status => {
                //聚焦尚未完成，球机延时1s再次调用获取状态
                if (status.Status !== 'Normal') {
                    setTimeout(() => {this.getStatus();}, 1000);
                } else {
                    this.setState({isAutoFocus: false});
                }
            });
        } else {
            getFocusStatus(channel).then(res => {
                if (res.Status !== 'Normal') {
                    this.b_normal = 0;
                    this.getStatus();
                } else if (!this.b_autofocus || this.b_normal === 5) { //如果web下发修改，并且还未出现"AutoFocus"，则直接返回不处理
                    this.b_normal = 0;
                    this.b_depth = false;
                    this.b_autodepth = false;
                    this.setState({isAutoFocus: false});
                } else if (this.b_normal < 5) {
                    this.getStatus();
                    this.b_normal = this.b_normal + 1;
                }
            });
        }
    }

    /**
     * @description 自动聚焦
     */
    autoFocus = () => {
        let {
            autoFocus
        } = this.props;
        autoFocus('', this.state.channel);
        this.setState({isAutoFocus: true},() => {
            autoFocus('', this.state.channel).then(() => this.getStatus());
        });
        // TODO 应该是从码流中获取状态，不建议通过rpc请求来获取

    }
    /**
     * @description 重置
     */
    reset = () => {
        let {
            adjustFocus
        } = this.props;
        this.setState({
            // currentZoom: 2,
            zoomStep: 20,
            focusStep: 20,
            // currentFocus: 2,
            isAutoFocus: true
        }, () => {
            adjustFocus({ 'focus': -1, 'zoom': -1 }, this.state.channel).then(() => this.getStatus());
        });
    }
    /**
     * @description 刷新
     */
    refresh = () => {
        let {
            currentChannel,
            getFocusStatus
        } = this.props;
        getFocusStatus(this.state.channel);
        // DTS001267449【0109】【WEB5.0】预览界面点击刷新按钮后一直显示选中状态
        // 神奇的bug
        let dom = document.querySelector('.handle_refresh');
        dom.blur();
    }

    /**
     * @description 点击单步增加或者减少
     * @param {*} type 增加/减少
     * @param {*} step 步长
     * @param {*} arr 聚焦/缩放
     */
    handle = (type, step, arr, flag) => {
        let tmp = '';
        let { sdDeviceType, channel, isAutoFocus } = this.state;
        let {
            ABFCamera: {
                zoomFocus
            },
            adjustFocus,
            stopAutoFocus,
            adjustFocusContinuously,
            currentChannel
        } = this.props;

        let stepLength = 1 / zoomFocus[arr] * step;
        arr === 'ZoomMotorSteps' ? tmp = 'Zoom' : tmp = 'Focus';

        //当用户修改后，置标志位。控件回调时不修改该值
        arr === 'ZoomMotorSteps' ? this.b_zoom = true : this.b_focus = true;
        this.b_autofocus = false;

        if (!flag) {
            this.setState({ longPress: false });
            this.longPressTimer = setTimeout(() => {
                this.setState({ longPress: true });
                if (sdDeviceType) {
                    // var stepValue = this.$('#zff_step').val() - 0;
                    // rpc.LensFunc.backFocusContinuously(val.oper === 'add' ? stepValue * 0.001 : -stepValue * 0.001).done(function() {
                    //     window.setTimeout(function() {
                    //         this.getStatusByRPC();
                    //     }, 500);
                    // });
                } else {
                    let params = {};
                    if (tmp === 'Zoom') {
                        type === 'add' ?
                            params = { 'focus': -1, 'zoom': 0.032 } :
                            params = { 'focus': -1, 'zoom': -0.032 };
                    } else {
                        type === 'add' ?
                            params = { 'focus': 0.02, 'zoom': -1 } :
                            params = { 'focus': -0.02, 'zoom': -1 };
                    }
                    adjustFocusContinuously(params, channel).then(() => { this.getStatus(); });
                }
            }, 300);
        } else {
            clearTimeout(this.longPressTimer);
            if (!this.state.longPress) {
                if ((zoomFocus[tmp] >= 1 && type === 'add') || (zoomFocus[tmp] <= 0 && type === 'reduce')) return;
                type === 'add' ? zoomFocus[tmp] += stepLength : zoomFocus[tmp] -= stepLength;
                if(zoomFocus[tmp] >= 1) zoomFocus[tmp] = 1;
                if(zoomFocus[tmp] <= 0) zoomFocus[tmp] = 0;
                // if (zoomFocus[tmp] === 1) return;
                // 这里在下发完正确配置之后，去请求当前状态数据时，会返回前一次的数据再返回正确的数据，通过定时器的方式来避免，可以后续优化
                if(tmp === 'Zoom') {
                    adjustFocus({ 'focus': zoomFocus.Focus, 'zoom': zoomFocus.Zoom }, channel);
                } else {
                    if(isAutoFocus) {
                        stopAutoFocus(channel)
                            .then(() => adjustFocus({ 'focus': zoomFocus.Focus, 'zoom': zoomFocus.Zoom }, channel))
                            .then(() => setTimeout(() => {this.getStatus();},100));
                    } else {
                        adjustFocus({ 'focus': zoomFocus.Focus, 'zoom': zoomFocus.Zoom }, channel).then(() => setTimeout(() => {this.getStatus();},100));
                    }
                }
            } else {
                if (tmp === 'Zoom') {
                    adjustFocusContinuously({ 'focus': -1, 'zoom': 0 }, channel);
                } else {
                    adjustFocusContinuously({ 'focus': 0, 'zoom': -1 }, channel);
                }
            }
        }
    }

    /**
     * @description 直接切换slide
     * @param {*} type 当前是聚焦还是缩放
     * @param {*} value 当前slide的值
     */
    changeSlide = (type, value) => {
        let {
            ABFCamera: {
                zoomFocus
            },
            updateZoomFocus,
            adjustFocus,
            stopAutoFocus,
            currentChannel
        } = this.props;

        //当用户修改后，置标志位。控件回调时不修改该值
        type === 'Focus' ? this.b_focus = true : this.b_zoom = true;
        this.b_autofocus = false;

        let obj = {};
        if (type === 'Focus') {
            value = value / zoomFocus.FocusMotorSteps;
            obj = { 'focus': value, 'zoom': zoomFocus.Zoom };
        }else{
            value = value / zoomFocus.ZoomMotorSteps;
            obj = { 'focus': zoomFocus.Focus, 'zoom': value };
        }
        // type === 'Focus' ? obj = { 'focus': value, 'zoom': zoomFocus.Zoom } : obj = { 'focus': zoomFocus.Focus, 'zoom': value };
        zoomFocus[type] = value;
        clearTimeout(this.iTime);
        this.iTime = setTimeout(() => {
            stopAutoFocus(this.state.channel).then(() => {
                adjustFocus(obj, this.state.channel);
            }).then(() => {
                this.getStatus();
            });
        }, 100);

    }
    /**
     * @description 切换当前模式下的步长基数
     * @param {*} step 步长
     * @param {*} type 聚焦还是缩放
     * @param {*} index 当前索引
     */
    changeStep = (step, type, index) => {
        // let current = '';
        // type === 'zoomStep' ? current = 'currentZoom' : current = 'currentFocus';
        this.setState({
            [type]: step,
            // [current]: index
        });
    }

    /**
     * @description 修改slide组件的显示数值，原本为接口返回的具体数值，修改为刻度显示
     * @memberof ZoomFocus
     */
    changeSlideShowNum = (type, e) => {
        let {
            ABFCamera: {
                zoomFocus
            }
        } = this.props;
        let len = 0;
        type === 'Focus' ? len = zoomFocus.FocusMotorSteps : len = zoomFocus.ZoomMotorSteps;
        return Math.round(e * len) + '';
    }

    onReginFocus = () => {
        this.setState({isClickReginFocus: true}, () => {
            plugin.ActivateReactAreaSelection('reactArea');
            pubsub.publish('preview_onRegionFocus', true);
        });
    }

    render () {
        let {
            ABFCamera,
            $t,
            sdZoomFocus
        } = this.props;
        const { zoomFocus } = ABFCamera;
        let { zoomStep, zoomList, focusStep, focusList, isShowFocus, isShowZoom,
            isAutoFocus, isShowReginFocus, LensInfo, sdDeviceType } = this.state;
        let Name = LensInfo === 'cs' ? $t('med.ABFFunction') : (sdDeviceType ? 'ABF' : $t('adv.AutoFocus'));
        return (
            <Row className='zoomFocus'>
                {
                    LensInfo === 'normal' &&
                    <div>
                        {/* 缩放 */}
                        {isShowZoom && zoomFocus && zoomFocus.ZoomMotorSteps > 0 &&
                            <Row className='row'>
                                {isShowFocus && <Col span={2}>
                                    <label>{$t('adv.Zoom')}</label>
                                </Col>}
                                <Col span={12} className='slideBox'>
                                    <Col className='point' style={{textAlign: 'right'}} span={4}>
                                        <label>{$t('adv.Wide')}</label>
                                        <Icon
                                            className={isAutoFocus ? 'refuse' : ''}
                                            type='_'
                                            onMouseDown={() => { this.handle('reduce', zoomStep, 'ZoomMotorSteps', false); }}
                                            onMouseUp={() => { this.handle('reduce', zoomStep, 'ZoomMotorSteps', true); }}
                                        />
                                    </Col>
                                    <Col span={16} className={(BrowserType() === 'ie9' || BrowserType() === 'ie10') ? 'preSlide' : 'slide'}>
                                        <Slider
                                            value={(zoomFocus && zoomFocus.Zoom) * (zoomFocus && zoomFocus.ZoomMotorSteps)}
                                            max={zoomFocus && zoomFocus.ZoomMotorSteps}
                                            min={0}
                                            step={zoomStep}
                                            onChange={(value) => this.changeSlide('Zoom', value)}
                                            disabled = { isAutoFocus }
                                        />
                                    </Col>
                                    <Col className='point' style={{textAlign: 'left'}} span={4}>
                                        <Icon
                                            className={isAutoFocus ? 'refuse' : ''}
                                            type='+'
                                            onMouseDown={() => { this.handle('add', zoomStep, 'ZoomMotorSteps', false); }}
                                            onMouseUp={() => { this.handle('add', zoomStep, 'ZoomMotorSteps', true); }}
                                        />
                                        <label>{$t('adv.Large')}</label>
                                    </Col>
                                </Col>
                                <Col span={6}>
                                    <Col className='step'>
                                        <LabelSelect
                                            label={$t('adv.Step')}
                                            options={zoomList}
                                            value={zoomStep}
                                            onChange={(value) => this.changeStep(value, 'zoomStep')} />
                                    </Col>
                                </Col>
                            </Row>
                        }
                        {/* 聚焦 */}
                        {isShowFocus && zoomFocus && zoomFocus.FocusMotorSteps > 0 &&
                            <Row className='row'>
                                { isShowZoom && <Col span={2}>
                                    <label>{$t('adv.Focus')}</label>
                                </Col>}
                                <Col span={12} className='slideBox'>
                                    <Col className='point' style={{textAlign: 'right'}} span={4}>
                                        <label>{$t('adv.Near')}</label>
                                        <Icon
                                            className={isAutoFocus ? 'refuse' : ''}
                                            type='_'
                                            onMouseDown={() => { this.handle('reduce', focusStep, 'FocusMotorSteps', false); }}
                                            onMouseUp={() => { this.handle('reduce', focusStep, 'FocusMotorSteps', true); }}
                                        />
                                    </Col>
                                    <Col span={16} className={(BrowserType() === 'ie9' || BrowserType() === 'ie10') ? 'preSlide' : 'slide'}>
                                        <Slider
                                            value={(zoomFocus && zoomFocus.Focus) * (zoomFocus && zoomFocus.FocusMotorSteps)}
                                            max={zoomFocus && zoomFocus.FocusMotorSteps}
                                            min={0}
                                            step={focusStep}
                                            onChange={(value) => this.changeSlide('Focus', value)}
                                            disabled = { isAutoFocus }
                                        />
                                    </Col>
                                    <Col className='point' style={{textAlign: 'left'}} span={4}>
                                        <Icon
                                            className={isAutoFocus ? 'refuse' : ''}
                                            type='+'
                                            onMouseDown={() => { this.handle('add', focusStep, 'FocusMotorSteps', false); }}
                                            onMouseUp={() => { this.handle('add', focusStep, 'FocusMotorSteps', true); }}
                                        />
                                        <label>{$t('adv.Far')}</label>
                                    </Col>
                                </Col>
                                <Col span={6}>
                                    <Col className='step'>
                                        <LabelSelect
                                            label={$t('adv.Step')}
                                            options={focusList}
                                            value={focusStep}
                                            onChange={(value) => this.changeStep(value, 'focusStep')} />
                                    </Col>
                                </Col>
                                {/* 热成像不支持自动聚焦 */}
                                {/* <Col span={2} offset={1}>
                                    <Button
                                        onClick={this.autoFocus}
                                        disabled={sdZoomFocus ? isAutoFocus : false}
                                        title={Name} >{Name}</Button>
                                </Col> */}
                            </Row>
                        }
                    </div>
                }
                <Row className='handleBar'>
                    <Col span={24}>
                        {sdZoomFocus &&
                        <div>
                            <Button style={{marginRight: 10}} onClick={this.reset} disabled={isAutoFocus} title={$t('adv.Reset')}>
                                {$t('adv.Reset')}
                            </Button>
                            <Button className='handle_refresh' onClick={this.refresh} disabled={isAutoFocus} title={$t('adv.Refresh')}>
                                {$t('adv.Refresh')}
                            </Button>
                            {isShowReginFocus &&
                                <Button onClick={this.onReginFocus} disabled={isAutoFocus} className='reginFocus'
                                    title={$t('adv.ReginFocus')}
                                >
                                    {$t('adv.ReginFocus')}
                                </Button>
                            }
                        </div>
                        }
                    </Col>
                </Row>
            </Row>
        );
    }
}

export default ZoomFocusSet;